:local(table) {
    border-collapse: collapse;
}

:local(td) {
    padding: 5px;
    border: solid 1px #ccc;
    text-align: left;
}

:local(.circle) {
    background-color: white;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid #000;
    margin: auto;
    color: #000;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

:local(.selected) {
    composes: circle;
    background-color: aquamarine;
}

:local(.used) {
    composes: circle;
    background-color: cornflowerblue;
}

:local(span .usedTimes) {
    font-size: small;
}