:local(.deleted) {
    text-decoration: line-through;
}

:local(.pointy) {
    cursor: pointer;
}

:local(td) {
    cursor: default;
}